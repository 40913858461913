<template>
  <div class="widgetContainer shippingAddress">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <span class="icon-Arrow-big-left cancel" @click="drawerBack()" />
        <p class="title">
          {{ $t('set_ATM_Pin') }}
        </p>
      </div>
    </div>
    <div class="widgetContainer__body">
      <el-form
        class="wise-form"
        ref="atmPinForm"
        :model="formData"
        :rules="formRules">
        <div id="dummy" class="field-wrapper d-none" />
        <!-- <input type="text" :value="getSelectedCard.last4" id="last4" name="last4"> -->
        <el-form-item prop="pin" :label="$t('enter_pin_text')">
          <div>
            <input-box @change="(code) => formData.pin = code" />
          </div>
        </el-form-item>

        <el-form-item prop="rePin" :label="$t('re-enter_pin_text')">
          <div>
            <input-box @change="(code) => formData.rePin = code" />
          </div>
        </el-form-item>
      </el-form>
      <p class="desclaimer-text">
        {{ $t('pin_use_desclaimer') }}
        <a target="_blank" :href="learnMoreUrl" v-if="isLearnMoreVisible">{{ $t('learn_more') }}</a>
      </p>
    </div>
    <div class="el-drawer__footer">
      <el-button data-testid="submit" type="primary" @click="next" class="el-button__brand brand width-100" :disabled="!isFormValid">
        {{ $t('save') }}
      </el-button>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import InputBox from '@/components/InputBox.vue';
import { getBrandConfig, getEnv } from '@/utils/env';

export default {
  name: 'SetATMPin',
  components: {
    InputBox
  },
  data() {
    var validateRePin = (rule, value, callback) => {
      if (value !== this.formData.pin) {
        callback(new Error(this.$t('pin_mismatch_error')));
      } else {
        callback();
      }
    };

    return {
      formData: {
        pin: '',
        rePin: ''
      },
      formRules: {
        rePin:[
          {
            validator: validateRePin
          }
        ]
      }
    };
  },
  computed: {
    ...mapGetters('card', ['getSelectedCard']),
    isFormValid() {
      return this.formData.pin && this.formData.pin.length === 4 && this.formData.pin === this.formData.rePin;
    },
    learnMoreUrl() {
      return getBrandConfig('atmPinLearnMore');
    },
    isLearnMoreVisible() {
      return getBrandConfig('atmPinLearnMore');
    }
  },
  mounted() {
    if(!window.VGSCollect) {
      const loader = this.showLoader();
      window.addVgsCollectScript(() => {
        this.loadForm();
        loader.close();
        
      })
    } else {
      this.loadForm();
    }
  },
  methods: {
    ...mapActions('card', ['pinToken']),
    loadForm() {
      let vaultId = getEnv('VUE_APP_VGS_VAULT_ID');
      const isProduction = getEnv('VUE_APP_ENV') === 'prod';

      this.form = window.VGSCollect.create(vaultId, isProduction ? 'live' : 'sandbox', () => {

      });

      this.form.field('#dummy', {
        type: 'text',
        name: 'dummy',
        // hideValue: true
      })

    },
    next() {
      this.$refs.atmPinForm.validate((valid) => {
        if (!valid) return;
        const loader = this.showLoader();
        const cardId = this.getSelectedCard.id;
        const card = this.getSelectedCard;

        const d = {
          'pin': this.formData.pin,
          'expiryMonth': card.expiryMonth,
          'expiryYear': card.expiryYear,
          'last4': card.last4
        }
        /**
         * get vgs collect token from api
         */
        this.pinToken(cardId)
          .then((res) => {
            const headers = {'sd-pin-token' : res.pinToken}
            this.form.submit(`/v1/card/${cardId}/pin`, {
              data: () => {
                return d;
              },
              headers
            }, (status, response) => {
              loader.close();
              if(status === 200 && response) {
                
                // this.drawerBack();
                this.$notify({
                  title: 'Success',
                  message: this.$t('pin_set_success'),
                  type: 'success'
                });
                this.drawerPush('CardManagement');
              }
              
            }, function(errors) {
              console.log('errors', errors);
              loader.close();
              // errors object:
              //{
              //  <invalid field name>: {
              //    <field state>
              //  },
              //}
            });
          })
          .catch((e) => {
            loader.close();
            this.apiErrorMessage(e);
          })
      });
    },
  }
};
</script>
<style lang="scss" scoped>
.d-none {
  display: none;
}
.shippingAddress {
  .el-drawer__body & {
    .default-drawer & {
      min-height: 100vh;
      .widgetContainer__body {
        display: flex;
        flex-direction: column;
        padding-bottom: 0;
      }
    }
  }
}
</style>
